import {gql} from '@apollo/client';

export const queries = {
  FETCH_APP_USER: gql`
    query ($id: ID, $origin: String) {
      appUser(id: $id, origin: $origin) {
        id
        userName
        email
        isPaymentCurrent
        zip
        trialInitiatedOn
        paymentType
        intercomHash
        uuid
      }
    }
  `,
};

export const FETCH_AFFILIATE_INFO = gql`
  query ($slug: String, $id: Int) {
    affiliate(slug: $slug, id: $id) {
      id
      slug
      name
      description
      url
      logoLink
      stripeProduct
      discountPercentage
      affiliateType
      blurb
      hasTrialPeriod
      extendedTrialPeriod
    }
  }
`;

export const mutations = {
  UPDATE_LAST_LOGIN: gql`
    mutation {
      updateLastLogin
    }
  `,
  LOGIN: gql`
    mutation ($email: String!, $password: String!) {
      login(email: $email, password: $password)
    }
  `,
  UPDATE_USER: gql`
    mutation ($id: ID!, $avad_id: String!) {
      updateUser(id: $id, avad_id: $avad_id)
    }
  `,
  VALIDATE_COGNITO_AUTH: gql`
    mutation ($idToken: String!, $accessToken: String!) {
      cognitoAuth(idToken: $idToken, accessToken: $accessToken)
    }
  `,
};

export const CREATE_USER = gql`
  mutation (
    $email: String!
    $password: String!
    $affiliateSlug: String
    $accountOrigin: String
  ) {
    createNewUser(
      email: $email
      password: $password
      affiliateSlug: $affiliateSlug
      accountOrigin: $accountOrigin
    )
  }
`;

export const START_TRIAL = gql`
  mutation startTrial {
    startTrial
  }
`;
