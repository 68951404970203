import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { get, remove } from "local-storage";
import { TOKEN } from "../context/user";
import typeDefs from "./typeDefs";

const cache = new InMemoryCache();

const authLink = new ApolloLink((operation, forward) => {
  const token = get(TOKEN);

  operation.setContext({
    headers: {
      authorization: token || "",
    },
  });

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_GRAPHQL_BASEURL,
});

const errorLink = onError(({ response, graphQLErrors }) => {
  if (
    graphQLErrors?.some(
      ({ message }) => message === "User is not logged in or token is invalid"
    )
  ) {
    response.errors = null;
    remove(TOKEN);
    remove("USER");
    window.location = window.location;
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

export const client = new ApolloClient({
  cache,
  link,
  typeDefs,
});
