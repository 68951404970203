import {useEffect, useRef, useState} from 'react';
import Head from 'next/head';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarProvider} from 'notistack';
import {ApolloProvider} from '@apollo/client';
import {Amplify} from 'aws-amplify';

import {client} from '../src/api/apolloClient';
import {UserProvider} from '../src/context/user';
import theme from '../src/styles/theme';
import Header from '../src/components/header/Header';
import {useRouter} from 'next/router';

import 'weather-icons/css/weather-icons.min.css';
import '../src/styles/main.css';
import '../src/styles/index.css';
import '../src/styles/video-react.css';
import '../src/styles/backgroundvideo.css';
import '../src/styles/stripe.css';
import PromoBanner from '../src/components/PromoBanner';
import {ampli} from '../src/ampli';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: 'auth.onwaterapp.com',
          scopes: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_IN],
          redirectSignOut: [process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGN_OUT],
          responseType: 'code',
        },
      },
    },
  },
});

const ENABLE_INTERCOM = process.env.NEXT_PUBLIC_ENABLE_INTERCOM === 'true';

function MyApp({Component, pageProps}) {
  const router = useRouter();
  const notistackRef = useRef(null);
  const [kochava, setKochava] = useState(null);

  useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined' && !kochava) {
        const {Kochava} = await import('kochava');
        let kochavaLocal = Kochava.createForReact();

        // Optional pre-start calls would go here
        kochavaLocal.startWithAppGuid('koonwater-web-ghrf9kx0');
        setKochava(kochavaLocal);
      }
    })();
  }, [kochava]);

  useEffect(() => {
    ampli.load({
      environment:
        process.env.NEXT_PUBLIC_AMPLI_ENVIRONMENT === 'development'
          ? 'default'
          : 'production',
    });
  }, []);

  // Material UI setup for nextjs compatability
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const Intercom = window['Intercom'];
    if (Intercom && ENABLE_INTERCOM) {
      Intercom('update');
    }
  }, [router.pathname]);

  if (router.pathname.includes('admin')) {
    return <Component {...{...pageProps, kochava}} />;
  }

  return (
    <>
      <Head>
        <title>onWater Fly Fishing: Explore Your Water</title>
        <meta
          name="description"
          content="Everything you need to plan a trip and enhance your day on the river. All in one place."
        />
      </Head>

      <div id="content">
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              ref={notistackRef}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              preventDuplicate
              onClick={() => {
                if (notistackRef.current) notistackRef.current.closeSnackbar();
              }}>
              <UserProvider {...kochava}>
                {!router.pathname.includes('smith-river') && (
                  <>
                    <Header />
                    {/* {!router.pathname.includes('checkout') && <PromoBanner />} */}
                  </>
                )}
                <Component {...{...pageProps, kochava}} />
              </UserProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ApolloProvider>
      </div>

      {/* The loader doesn't appear to be needed with nextjs */}
      {/* <div id="loader">
        <img id='spinner' src="/assets/images/utility/spinner.png" alt="Spinner" />
        <img id='loadgo' src="/assets/images/utility/loadlogo.png" alt="Load Logo" />
      </div> */}
    </>
  );
}

export default MyApp;
