import { createTheme } from '@material-ui/core/styles'

const padding = { padding: "0.7em 1em !important" };
const secondary = "#0ACF83";
const rubikFont = [
  'Rubik',
  'Arial',
  'Helvetica',
  'sans-serif',
].join(',');
const figtreeFont = [
  'Figtree',
  'Arial',
  'Helvetica',
  'sans-serif',
].join(',');
const black = "#122320";
const lightGray = "#F3F0E9";
const theme = createTheme({
  palette: {
    primary: { main: "#FFB932" },
    secondary: { main: secondary},
    accent: { main: secondary },
    background: { default: "#122320", light: "rgba(243, 241, 239, .5)" },
    divider: "rgb(23, 88, 146)",
    text: { primary: black, light: "#848484" },
    common: {
      white: "#FEFEFE",
      black: black,
      grey: "#868F98",
      lightGray: lightGray,
    },
    border: "#E8E8E8",
    light: "rgba(255,255,255,0.8)",
    placeholder: "rgba(231, 231, 233, 0.3)",
  },
  typography: {
    h1: {
      fontFamily: rubikFont,
      fontWeight: 500,
    },
    h2: {
      fontFamily: rubikFont,
      fontWeight: 500,
    },
    h4: {
      fontFamily: rubikFont,
      fontWeight: 500,
    },
    body1: {
      fontFamily: figtreeFont,
    },
    fontFamily: rubikFont,
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: lightGray,
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      }
    },
    MuiInputBase: {
      input: padding,
      multiline: {
        padding: "0 !important",
      },
      root :{
        backgroundColor: `rgba(0, 0, 0, 0.03)`
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 10px) scale(1)",
      },
    },
    MuiList: {
      root: {
        maxWidth: 415,
      }
    },
    MuiLink: {
      root: {
        color: secondary,
        fontWeight: "bold",
        textDecoration: "none",
        "&:visited": {
          color: secondary,
        },
        "&:hover": {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "500",
        borderRadius: 8,
        padding: '0.7em 1em',
        boxShadow: "none",
        lineHeight: 1.3,
      },
      outlined: {
        padding: '0.57em 1em',
      },
      outlinedPrimary: {
        borderColor: black,
        color: `rgba(#122320, 0.2)`,
        borderRadius: 8,
        fontWeight: "400",
        fontSize: "0.8rem",
        padding: '0.7em 1em',
      },
      containedPrimary: {
        color: black,
        boxShadow: "none",
      }
    },
    MuiDivider: {
      root: {
        width: "100%",
        height: "2px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*, *::before, *::after": {
          boxSizing: "none",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: figtreeFont,
      },
    }
  },
});

export default theme;
