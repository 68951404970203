import { gql } from "@apollo/client";

export default gql`
  # ANGLING PRESSURE
  type AnglingPressure {
    id: ID!
    month: String!
    amount: Int!
  }

  input AnglingPressureInput {
    id: ID
    month: String!
    amount: Int!
  }

  # UPLOAD
  type UploadInput {
    name: String!
    type: String!
  }

  # BODY OF WATER
  type BodyOfWater {
    id: ID!
    name: String!
    geometry: String!
    description: String
    states: [String]
    length: String
    fishRangeMin: String
    fishRangeMax: String
    flowDirectionCoords: String
    anglingPressure: [AnglingPressure]
    pointsOfInterest: [PointOfInterest]
  }

  input BodyOfWaterInput {
    id: ID
    name: String!
    states: [String]!
    geometry: String!
    description: String
    fishRange: String
    flowDirectionCoords: String
    transportation: String
    regulations: String
    stateRegulations: String
    flyFishingReports: String
    shops: String
  }

  # POINT OF INTEREST
  type PointOfInterestType {
    id: ID!
    name: String!
  }

  type PointOfInterest {
    id: ID!
    name: String
    description: String
    bodyOfWaterId: ID
    geometry: String!
    phone: String
    url: String
    address: String
    pointOfInterestType: PointOfInterestType!
  }

  input PointOfInterestInput {
    geometry: String!
    bodyOfWaterId: Int!
    pointOfInterestTypeId: Int!
    # properties
    id: ID
    name: String
    description: String
    url: String
    phone: String
    address: String
  }

  # USER

  type AppUser {
  id: ID!
  userName: String
  password:String!
  email:String!
  favoritebodiesofwater: [String]!
  isPaymentCurrent: Boolean
  }

`;
